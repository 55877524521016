import React, { PureComponent } from 'react'
import { ProductDetail, FullHeightRow, SpacedCol, Link, FullHeightGrid, i18n, styled, updateContextState, context } from '@workrails/wr-catalog-base'
import { submitProposal } from '../../utils/proposals/submit-proposal'
import Html from './overrides/html'
import Bundles from '../../components/bundles'

const StryledProductDetail = styled.div`
  .product-detail-header {
    text-align: left;
  }

  .product-detail-price {
    font-size: 18px;
    opacity: 0.5;
    margin-top: 5px;
    display: block;
  }

  .product-details-add-to-order button {
    border-radius: 5px;
  }

  .product-detail-price p {
    color: #333;
    font-weight: 800;
  }

  .product-details-description-card {
    font-size: 18px;
  }

  .documents-table path {
    fill: #0696d7;
  }

  .dropdown-list li {
    list-style-type: none;
    margin: 0;
  }

  .product-details-cart input,
  .product-details-cart .react-date-picker > div {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-style: solid;
    padding-left: 0;
    outline: none;
  }

  .product-details-cart button {
    border-radius: 5px;
  }

  table td {
    border: 1px solid #ccc;
    padding: 10px;
  }

  ul li {
    list-style-type: disc;
    display: list-item;
    margin: 0 18px;
  }

  ol li {
    list-style-type: decimal;
  }

  ol {
    margin: 0 13px;
  }
`

const StyledLink = styled(Link)`
  flex: 0 0 auto;
  align-self: end;
  border-radius: 5px;
  overflow: hidden;

  span {
    display: inline-block;
    ${({ theme }) => theme.components.native.button}
  }
`

class ProductView extends PureComponent {
  handleChange = (data) => {
    console.log('data', data)
  }

  addToSOW = () => {
    return (
      <StyledLink href='#/landing-page/f7129ae5-2137-4abc-812e-dbf37b5ce8f1' target='_blank'>
        <span>Next</span>
      </StyledLink>
    )
  }

  overrides = {
    html: Html,
    'component:bundles': Bundles,
  }

  componentDidMount () {
    updateContextState((state) => {
      state.selectedCatalogId = undefined
    })
  }

  render () {
    const { match } = this.props
    const headerRowsColors = [
      {
        label: 'Partner Enabled',
        color: '#86c146',
      }, {
        label: 'Estimated Effort',
        color: '#02b1f1',
      }, {
        label: 'Virtual / Onsite',
        color: '#12cce0',
      }, {
        label: 'Service Code',
        color: '#9dca6c',
      }, {
        label: 'Offering Type',
        color: '#ffce55',
      },
    ]

    return (
      <StryledProductDetail>
        <FullHeightGrid fluid>
          <FullHeightRow middle='xs' center='xs'>
            <SpacedCol xs={12}>
              <ProductDetail
              getProduct={(product) =>{
                context.state.currentProduct = product
              }}
                productId={match.params.productId}
                bundleId={match.params.bundleId}
                onChange={this.handleChange}
                onSubmit={submitProposal}
                headerRowsColors={headerRowsColors}
                addButtonLabel={i18n('productDetails.addToSOW')}
                addedButtonLabel={i18n('productDetails.addedToSOW')}
                AddToSOW={this.addToSOW}
                asPdf
                asDoc
                showPrice
                showHeaderRows
                overrides={this.overrides}
              />
            </SpacedCol>
          </FullHeightRow>
        </FullHeightGrid>
      </StryledProductDetail>
    )
  }
}

export default ProductView
