import { context } from '@workrails/wr-catalog-base'
import routes from './routes'
import theme from './theme'
import { getFilters, getProductRow } from './utils/product/product-utils'
import './utils/logout-old-sso'

const autodeskAccessToken = context.persistentStorage.get('autodeskAccessToken')

export default {
  hashHistory: true,
  state: {
    pointSystem: true,
    isLoggedIn: process.env.NODE_ENV === 'development' || Boolean(autodeskAccessToken),
    selectedIndustries: {},
    activeSorting: null,
    ...(process.env.NODE_ENV === 'development' || autodeskAccessToken
      ? {
        user:
          {
            email: 'autodesk@workrails.com',
          },
      }
      : {}
    ),
  },
  actions: {
    logout: () => {
      context.persistentStorage.remove('autodeskAccessToken')
      context.persistentStorage.remove('token')
      window.location.reload()
    },
  },
  theme,
  constants: {
    host: process.env.REACT_APP_WR_API_HOST || 'https://api.workrails.com',
    appHost: process.env.REACT_APP_WR_APP_HOST || 'https://app.workrails.com',
    token: process.env.REACT_APP_WR_ACCESS_TOKEN || '0df6b004-8335-44e9-b371-731b8f0f8406',
    transactionsEnabled: true,
    autodeskAccessToken: autodeskAccessToken,
    client: {
      catalogIds: [
        'c532b306-a96b-40bf-b256-14578497d539',
        '4f9f2fcd-d46a-47ae-865f-f220bf3c0fc5',
        '81ceda0f-31d6-47b1-8d32-b8d9208821c1',
      ],
      companyId: process.env.REACT_APP_WR_COMPANY_TOKEN || '41c7f349-05ca-4f4d-b851-8fba6d82bed6',
    },
    product: {
      list: {
        gridColSizes: { sm: 6, md: 4, lg: 4 },
      },
    },
  },
  utils: {
    dropdownInput: {},
    products: {
      getFilters,
    },
    transactions: {
      processTransaction: (transaction) => {
        transaction.requestData.ssoInfo = {
          email: context.persistentStorage.get('autodeskEmail'),
          country: context.persistentStorage.get('autodeskCtry'),
          organization: context.persistentStorage.get('autodeskOrganization'),
          department: context.persistentStorage.get('autodeskDepartment'),
        }
        if (transaction.requestData.object.type === 'product') {
          const product = context.state.currentProduct
          transaction.requestData.object.offeringType = getProductRow(product, 'label', 'Offering Type')?.control || ''
          }
      },
    },
  },
  routes,
}
