import React, { PureComponent, Fragment } from 'react'
import { updateContextState, ErrorText, CenteredText, Button, Link, context } from '@workrails/wr-catalog-base'

class TokenRedirect extends PureComponent {
  componentDidMount () {
    const { match: { params: { token } } } = this.props

    if (token) {
      updateContextState((state) => {
        state.autodeskToken = token
        state.isLoggedIn = true
        state.user = {
          email: 'placeholder@test.com',
        }
      })
    }
  }

  render () {
    const { match: { params: { token } } } = this.props

    return (
      token
        ? (<div />)
        : (
          <Fragment>
            <CenteredText>
              <ErrorText>There was an error while processing your request. Please click the button below to try again.</ErrorText>
              <Link to={context.getRoutePath('login')}>
                <Button>Retry</Button>
              </Link>
            </CenteredText>
          </Fragment>
        )
    )
  }
}

export default TokenRedirect
