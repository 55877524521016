import logo from './assets/autodesk-black.svg'

export default {
  palette: {
    primary: '#0696d7',
    secondary: '#0477a8',
  },
  font: {
    family: 'Artifakt',
    size: '1em',
    lineHeight: '1.15',
  },
  brand: {
    // name: 'Autodesk Service Catalog',
    // tagline: 'Autodesk Service Offerings are defined sets of activities and outputs that are designed to help our customers achieve specific business outcomes.',
    logo,
  },
  components: {
    hero: {
      backgroundImage: 'none',
      backgroundPosition: '50% 80%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '300px',
      padding: '55px 0',
      top: '50%',

      brandName: {
        brandNameHeight: '52px',
        brandNameFontWeight: '500',
      },

      tagline: {
        taglineHeight: '13px',
        taglineLineHeight: '1.5',
        taglineMaxWidth: '1000px',
        taglineMargin: '0 auto',
      },
    },
    card: {
      borderColor: '#ddd',
      borderColorHover: '#ccc',
      borderRadius: '0',
      selectedIconColor: '#888',
    },

    dropdown: {
      borderRadius: '5px',
    },
    navbar: {
      height: '65px',
      position: 'fixed',
      backgroundColor: '#fff',
      color: '#999',
    },
    button: {
      padding: '0.5rem 1rem',
    },
    decisionTree: {
      displaySelectedIcon: true,
      answers: {
        backgroundColor: '#e0e0e0',
        border: '1px solid #d6d6d6',
        color: '#333',
        selectedAnswer: '#e0e0e0',
        fontWeight: 'bold',
        selectedAnswerBorder: '1px solid #afafaf',
      },
      footer: {
        buttonBorderRadius: '5px',
      },
      radio: {
        checkedColor: '#fff',
      },
    },
    dropdownInput: {
      selectedColor: '#CED4DA',
    },
    modal: {
      borderRadius: '5px',
      confirmation: {
        buttons: {
          borderRadius: '5px',
          padding: '0.75rem 1rem',
        },
      },
    },
    productDetails: {
      headerRows: {
        borderRadius: '5px',
        padding: '10px 15px',
        colors: [
          {
            backgroundColor: '#86c146',
            filter: 'none',
            color: '#fff',
          }, {
            backgroundColor: '#02b1f1',
            filter: 'none',
            color: '#fff',
          }, {
            backgroundColor: '#12cce0',
            filter: 'none',
            color: '#fff',
          }, {
            backgroundColor: '#86c146',
            filter: 'none',
            color: '#fff',
          }, {
            backgroundColor: '#02b1f1',
            filter: 'none',
            color: '#fff',
          }, {
            backgroundColor: '#12cce0',
            filter: 'none',
            color: '#fff',
          }, {
            backgroundColor: '#86c146',
            filter: 'none',
            color: '#fff',
          }, {
            backgroundColor: '#02b1f1',
            filter: 'none',
            color: '#fff',
          }, {
            backgroundColor: '#12cce0',
            filter: 'none',
            color: '#fff',
          },
        ],
      },
    },
  },
}
