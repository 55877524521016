import React, { PureComponent } from 'react'
import { ProductDetail, FullHeightRow, SpacedCol, FullHeightGrid, i18n, styled, context, updateContextState } from '@workrails/wr-catalog-base'
import { submitProposal } from '../../utils/proposals/submit-proposal'

const StryledProductDetail = styled.div`
  .product-detail-header {
    text-align: left;
  }

  .product-detail-price {
    font-size: 18px;
    opacity: 0.5;
    margin-top: 5px;
    display: block;
  }

  .product-details-add-to-order button {
    border-radius: 5px;
  }

  .product-detail-price p {
    color: #333;
    font-weight: 800;
  }

  .product-details-description-card {
    font-size: 18px;
  }

  .documents-table path {
    fill: #0696d7;
  }

  .dropdown-list li {
    list-style-type: none;
    margin: 0;
  }

  .product-details-cart input,
  .product-details-cart .react-date-picker > div {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-style: solid;
    padding-left: 0;
    outline: none;
  }

  .product-details-cart button {
    border-radius: 5px;
  }

  table td {
    border: 1px solid #ccc;
    padding: 10px;
  }

  ul li {
    list-style-type: disc;
    display: list-item;
    margin: 0 18px;
  }

  ol li {
    list-style-type: decimal;
  }

  ol {
    margin: 0 13px;
  }
`

class SpecialProductView extends PureComponent {
  handleChange = (data) => {
    console.log('data', data)
  }

  addToSOW = () => {
    return null
  }

  render () {
    const { match } = this.props
    const headerRowsColors = [
      {
        label: 'Partner Enabled',
        color: '#86c146',
      }, {
        label: 'Estimated Effort',
        color: '#02b1f1',
      }, {
        label: 'Virtual / Onsite',
        color: '#12cce0',
      }, {
        label: 'Service Code',
        color: '#9dca6c',
      },
    ]

    return (
      <StryledProductDetail>
        <FullHeightGrid fluid>
          <FullHeightRow middle='xs' center='xs'>
            <SpacedCol xs={12}>
              <ProductDetail
              getProduct={(product) =>{
                context.state.currentProduct = product
              }}
                productId={match.params.productId}
                bundleId={match.params.bundleId}
                onChange={this.handleChange}
                onSubmit={submitProposal}
                headerRowsColors={headerRowsColors}
                addButtonLabel={i18n('productDetails.addToSOW')}
                addedButtonLabel={i18n('productDetails.addedToSOW')}
                AddToSOW={this.addToSOW}
                asPdf
                asDoc
                showPrice
                showHeaderRows
              />
            </SpacedCol>
          </FullHeightRow>
        </FullHeightGrid>
      </StryledProductDetail>
    )
  }
}

export default SpecialProductView
