import React, { Component } from 'react'
import propTypes from 'prop-types'
import { object, string, date } from 'yup'
import { get as safeGet } from 'lodash'
import { FaRegFilePdf, FaRegFileWord } from 'react-icons/fa'
import { IoMdArrowDropdown, IoIosRefresh } from 'react-icons/io'
import {
  styled,
  context,
  withContext,
  connectRequest,
  modules,
  i18n,
  formatCurrency,
  ProductList,
  Formik,
  Form,
  FormikInput,
  FormikButton,
  FormikDatepicker,
  FormikTextarea,
  Dropdown,
  Grid,
  Row,
  Col,
  Progress,
  MutedText,
  MediumText,
  EngravedText,
  CenteredText,
} from '@workrails/wr-catalog-base'

const { requests, utils } = modules.product

const Product = styled.div`
  padding-top: 15px;

  h3 {
    font-weight: 500;
    font-size: 16px;
    padding: 15px 0 0;
    display: block;
  }

  p {
    margin-bottom: 10px;
  }

  input,
  textarea {
    margin-top: 15px;
  }

  label,
  .react-date-picker__wrapper {
    margin-top: 15px;
  }

  label .react-date-picker__wrapper {
    margin-top: 0px;
  }

  .react-date-picker__inputGroup input {
    margin: 0;
  }
`

const ModalDescription = styled.p`
  padding: 20px;
  text-align: center;
`

const ProductDescription = styled.p`
  margin-top: 20px;
`

const ProductPricing = styled.p`
  color: ${({ theme }) => theme.palette.primary};
  margin-top: 10px;
  font-weight: 500;
`

const StyledProgress = styled(Progress)`
  margin: 20px 0;
`

const Products = styled.div`
  padding: 20px;

  button {
    width: 100%;
    border-radius: 5px;
    padding: 12px;
  }
`

const validationSchema = object({
  name: string().required('Name is a required field'),
  company: string().required('Company is a required field'),
  email: string().email().required('Email is a required field'),
  desiredStartDate: date(),
  notes: string(),
})

class BuildQuote extends Component {
  constructor (props) {
    super(props)
    this.asDraft = false
    this.asQuotePdf = false
    this.asQuoteWord = false
  }

  setSubmitType = (submitType) => {
    this.asDraft = false
    this.asQuotePdf = false
    this.asQuoteWord = false

    if (submitType === 'asDraft') {
      this.asDraft = true
    }

    if (submitType === 'asQuotePdf') {
      this.asQuotePdf = true
    }

    if (submitType === 'asQuoteWord') {
      this.asQuoteWord = true
    }
  }

  static propTypes = {
    initialValues: propTypes.object,
    showVMSButton: propTypes.bool,
  }

  static defaultProps = {
    initialValues: {
      name: '',
      company: '',
      email: '',
      desiredStartDate: '',
      notes: '',
    },
    showVMSButton: false,
  }

  handleSubmit = (values, { setSubmitting }) => {
    if (this.asQuotePdf) {
      this.props.submitProposal({
        asQuotePdf: this.asQuotePdf,
        formValues: values,
        fromDecisionTree: true,
      })

      this.props.context.actions.openModal({
        showClose: false,
        body: (
          <>
            <ModalDescription>We are generating your proposal</ModalDescription>
            <Progress />
          </>
        ),
      })
    }

    if (this.asQuoteWord) {
      this.props.submitProposal({
        asQuoteWord: this.asQuoteWord,
        formValues: values,
        fromDecisionTree: true,
      })

      this.props.context.actions.openModal({
        showClose: false,
        body: (
          <>
            <ModalDescription>We are generating your proposal</ModalDescription>
            <Progress />
          </>
        ),
      })
    }

    setSubmitting(false)
  }

  updateCart = (product) => {
    const priceRow = product.rows.find(row => row.rowType === 'component:pricing')
    const priceRowControl = priceRow.control && typeof priceRow.control === 'string' ? JSON.parse(priceRow.control) : priceRow.control
    const price = priceRowControl && priceRowControl.constants ? priceRowControl.constants.find(c => c.name === 'cost') : null

    product.price = price ? price.value / 100 : product.service_rate_cents ? product.service_rate_cents : 0

    if (!this.contextUpdated) {
      this.props.context.actions.addProductToDecisionTreeCart(product)
      this.contextUpdated = true
    }
  }

  render () {
    const {
      Body,
      Footer,
      DecisionTreeBody,
      DecisionTreeFooter,
      question,
      animateContent,
      fetchProduct,
      asDoc,
      asPdf,
      showVMSButton,
      initialValues,
    } = this.props
    const isProduct = question.type === 'product'
    const isProducts = question.type === 'products'
    const products = question.products || []
    const bundles = question.bundles || []
    const childValue = question.childValue || []

    if (isProducts && (products.length > 0 || bundles.length > 0)) {
      return (
        <Products>
          <Grid>
            <Row>
              <Col xs={12}>
                <p>We have found a few products that will suit you:</p>
              </Col>
            </Row>
            <Row>
              {childValue.map((product, index) => {
                const cprod = safeGet(this.props.fetchCatalog.value, 'contents.items', []).find(p => p.id === product.id)
                return (
                  <ProductList.Product
                    key={product.id || index}
                    product={cprod || {}}
                    categoryClassNameMapping={[]}
                    displayPriceOnList
                    showHeaderRows
                    configureButtonLabel='Explore'
                    productTarget='_blank'
                    headerRowsColors={[
                      {
                        label: 'Partner Enabled',
                        color: '#86c146',
                      }, {
                        label: 'Estimated Effort',
                        color: '#02b1f1',
                      }, {
                        label: 'Virtual / Onsite',
                        color: '#12cce0',
                      }, {
                        label: 'Service Code',
                        color: '#9dca6c',
                      }, {
                        label: 'Offering Type',
                        color: '#ffce55',
                      },
                    ]}
                  />
                )
              })}
            </Row>
          </Grid>
        </Products>
      )
    }

    let product = null
    let pricing = null

    if (isProduct && fetchProduct.pending) {
      return (<StyledProgress />)
    } else if (isProduct && fetchProduct.rejected) {
      return (
        <Grid>
          <MediumText>
            <CenteredText>
              <EngravedText>
                <MutedText>{i18n('decisionTree.quoteBuilder.errors.buildQuote')}</MutedText>
              </EngravedText>
            </CenteredText>
          </MediumText>
        </Grid>
      )
    }

    if (isProduct && fetchProduct.fulfilled) {
      product = fetchProduct.value

      const pricingRow = product.rows.find(row => row.rowType === 'component:pricing')
      const pricingValue = { schedule: { serviceRateLabel: formatCurrency(utils.getPrice(product)) } }

      pricing = pricingRow && pricingRow.control ? typeof pricingRow.control === 'string' ? JSON.parse(pricingRow.control) : pricingRow.control : pricingValue

      this.updateCart(product)
    }

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <Form noValidate>
          <>
            <Body>
              <DecisionTreeBody className={animateContent ? 'toggleAnimation' : ''}>
                {isProduct && product &&
                  <Grid>
                    <Product>
                      <Row>
                        <Col xs={12}>
                          <p>{i18n('decisionTree.quoteBuilder.form.description')}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <h3>{i18n('decisionTree.quoteBuilder.form.theBasics')}</h3>
                          <FormikInput fullWidth large name='name' placeholder={i18n('proposalGeneration.form.name')} />
                          <FormikInput fullWidth large name='company' placeholder={i18n('proposalGeneration.form.company')} />
                          <FormikInput fullWidth large name='email' type='email' placeholder={i18n('proposalGeneration.form.email')} />

                          <h3>{i18n('proposalGeneration.form.desiredStartDate')}</h3>
                          <FormikDatepicker fullWidth large name='desiredStartDate' />

                          <h3>{i18n('decisionTree.quoteBuilder.form.anythingElse')}</h3>
                          <FormikTextarea noResize fullWidth large name='notes' placeholder={i18n('decisionTree.quoteBuilder.form.addHere')} />
                        </Col>
                        <Col xsOffset={1} xs={5}>
                          <h3>{product.name}</h3>
                          <ProductDescription>{product.description}</ProductDescription>
                          <ProductPricing>{pricing.schedule.serviceRateLabel}</ProductPricing>
                        </Col>
                      </Row>
                    </Product>
                  </Grid>
                }

                {question.type === 'message' && 'Waiting for more items to be added!'}
              </DecisionTreeBody>
            </Body>
            <Footer className='decision-tree-footer'>
              <DecisionTreeFooter>
                <FormikButton fullWidth primary type='button' onClick={this.props.resetDecisionTree} className='start-over'>
                  <IoIosRefresh size={20} />
                  {i18n('decisionTree.quoteBuilder.startOver')}
                </FormikButton>
                <Dropdown>
                  {({ DropdownTrigger, DropdownList }) => (
                    <>
                      <DropdownTrigger>
                        <FormikButton fullWidth primary type='button'>
                          {i18n('proposalGeneration.generateProposal')}
                          <IoMdArrowDropdown size={20} />
                        </FormikButton>
                      </DropdownTrigger>
                      <DropdownList>
                        <ul>
                          {asPdf &&
                            <li onClick={() => { this.setSubmitType('asQuotePdf') }}>
                              <FormikButton fullWidth primary={false} type='submit'>
                                {i18n('proposalGeneration.asAPdf')}
                                <FaRegFilePdf size={20} color={this.props.context.theme.components.dropdown.color} />
                              </FormikButton>
                            </li>
                          }
                          {asDoc &&
                            <li onClick={() => { this.setSubmitType('asQuoteWord') }}>
                              <FormikButton fullWidth primary={false} type='submit'>
                                {i18n('proposalGeneration.asAWord')}
                                <FaRegFileWord size={20} color={this.props.context.theme.components.dropdown.color} />
                              </FormikButton>
                            </li>
                          }
                        </ul>
                      </DropdownList>
                    </>
                  )}
                </Dropdown>
                {showVMSButton &&
                  <FormikButton className='send-to-vms' fullWidth primary type='button' disabled>
                    {i18n('proposalGeneration.sendToVms')}
                  </FormikButton>
                }
              </DecisionTreeFooter>
            </Footer>
          </>
        </Form>
      </Formik>
    )
  }
}

export default connectRequest(props => {
  const isProduct = props.question.type === 'product' && props.question.productId

  return ({
    fetchProduct: isProduct ? requests.fetchProductById(props.question.productId) : { value: { pending: false, rejected: true } },
    fetchCatalog: requests.fetchProductsByCatalogId(context.constants.client.catalogIds[0]),
    // companyProducts: {
    //   url: `${context.constants.appHost}/api/v1/service-catalogs/products/search`,
    //   method: 'post',
    //   comparison: context.constants.client.companyId,
    //   body: JSON.stringify({
    //     data: { companyId: context.constants.client.companyId }
    //   }),
    //   then: ({ data }) => ({ value: data })
    // }
  })
})(withContext(BuildQuote))
