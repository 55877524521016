import React from 'react'
import {
  styled,
  context,
  updateContextState,
} from '@workrails/wr-catalog-base'
import buildingDesign2 from '../../assets/Building Design Button.svg'
import civilEngineering2 from '../../assets/Civil Engineering Button.svg'
import construction2 from '../../assets/Construction Button.svg'
import manufacturing2 from '../../assets/Manufacturing Button.svg'
import crossIndustry from '../../assets/Cross Industry Button.svg'

const HeroContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.components.hero.marginBottom};
  padding: 20px 0 40px 0;
`
const IndustryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const IndustryButton = styled.button`
  border-radius: 13px;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  width: 120px;
  transition: all 200ms;

  &:hover {
    filter: brightness(92%);
  }

  &.active {
    filter: brightness(50%);
  }
`

export const INDUSTRY_BUTTONS = [
  {
    category: 'Building Design',
    label: 'Building Design',
    img: buildingDesign2,
  },
  {
    category: 'Civil Engineering',
    label: 'Civil Engineering',
    img: civilEngineering2,
  },
  {
    category: 'Construction',
    label: 'Construction',
    img: construction2,
  },
  {
    category: 'Manufacturing',
    label: 'Manufacturing',
    img: manufacturing2,
  },
  {
    category: 'Cross-Industry',
    label: 'Cross-Industry',
    img: crossIndustry,
  },
]

export default function IndustryToggles (props) {
  return (
    <HeroContainer>
      <IndustryContainer>
        {INDUSTRY_BUTTONS.map((btn, i) => {
          return (
            <IndustryButton
              key={i}
              className={context.state.selectedIndustries[btn.category] ? 'active' : ''}
              onClick={() => {
                updateContextState((state) => {
                  state.selectedIndustries[btn.category] = !state.selectedIndustries[btn.category]
                })
              }}
            >
              <img src={btn.img} alt='' />
            </IndustryButton>
          )
        })}
      </IndustryContainer>
    </HeroContainer>
  )
}
