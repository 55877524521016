import React, { PureComponent, Fragment } from 'react'
import {
  ProductList,
  styled,
  Row,
  Col,
  CategoriesDropdown,
  context,
  CenteredText,
  MediumText,
} from '@workrails/wr-catalog-base'
import { filterProducts, retrieveProducts } from '../../utils/product/product-utils'
import { INDUSTRY_BUTTONS } from '../industry-toggles'

const industries = new Set(INDUSTRY_BUTTONS.map(btn => btn.category))

const StyledList = styled.div`
  .product-list-price {
    color: #3a3a3a;
    font-size: 16px;
    padding: 10px;
    display: block;
  }

  .product-list-price p {
    font-weight: 600;
  }

  [class^=toolbar__] input:focus {
    border-color: #ced4da;
    box-shadow: none;
    outline: none;
  }

  .product-name {
    font-size: 1.2em;
    line-height: 1.4em;
    font-weight: 600;
  }

  .category-filter {
    margin-top: 10px;
    border-radius: 20px;
  }

  .category-filter:hover {
    background-color: rgba(248, 151, 29, 0.1)
  }

  .category-filter.selected:hover {
    opacity: 0.8;
    background-color: rgba(248, 151, 29, 1)
  }

  .category-filter,
  .category-filter:hover {
    transition: background-color 0.3s, opacity 0.3s, color 0.3s;
  }

  .products-container > button {
    width: 100%;
    border-radius: 5px;
    padding: 12px;
  }

  .wr__product-header {
    height: 120px;
  }

  .product-description {
    font-size: 14px;
    margin-bottom: 1.5rem;
    padding-bottom: 0;

    > p {
      height: 130px;
      overflow-y: auto;
      padding-bottom: 0;
    }
  }

  .title {
    margin: 50px 0 20px;
    text-align: center;
    font-size: 25px;
    color: #0477a8;
  }
`

const Container = styled.div`
  margin-top: 1rem;
  position: relative;
  .dropdown-input {
    text-align: left;
  }
`

class CustomProductList extends PureComponent {
  state = {
    productsCount: 0,
  }

  retrieveProducts = (products = {}, searchFilter) => {
    const { productIdsToRender, sortByAccelerators } = this.props

    const _products = retrieveProducts(products, searchFilter, sortByAccelerators)

    let result
    if (productIdsToRender && productIdsToRender.length) {
      // productIdsToRender holds the desired order of items
      result = productIdsToRender
        .map((id) => {
          return (
            this.props[`fetchProduct:${id}`].value ||
            _products.find(v => v.id === id)
          )
        })
        .filter(Boolean)
    } else {
      result = _products
    }

    this.setState({
      productsCount: result.length,
    })

    return result
  }

  renderToolbar = (renderedParts) => {
    const categories = context.state.categories.filter(cat => !industries.has(cat.name))
    return (
      <Container>
        <Row>
          <Col xs>{renderedParts.textFilter}</Col>
          <Col xs style={{ flexGrow: 0 }}>{renderedParts.filtersDropdown}</Col>
        </Row>
        <br />
        {!this.props.noCategoryFilter &&
          <CategoriesDropdown
            filterByCategoryLabel='Filter by Product'
            categories={categories}
          />
        }
      </Container>
    )
  }

  render () {
    const colSize = {
      lg: 8,
      md: 10,
      sm: 12,
      xs: 12,
    }

    const headerRowsColors = [
      {
        label: 'Partner Enabled',
        color: '#86c146',
      }, {
        label: 'Estimated Effort',
        color: '#02b1f1',
      }, {
        label: 'Virtual / Onsite',
        color: '#12cce0',
      }, {
        label: 'Service Code',
        color: '#9dca6c',
      }, {
        label: 'Offering Type',
        color: '#ffce55',
      }, {
        label: 'Bundle Count',
        color: '#9dca6c',
      },
    ]

    return (
      <StyledList>
        {this.props.renderProductCount && this.state.productsCount > 0 && (
          <Fragment>
            <br />
            <CenteredText>
              <MediumText>
                {this.state.productsCount} {this.props.productCountLabel}
              </MediumText>
            </CenteredText>
          </Fragment>
        )}
        <ProductList
          toolBarColSize={colSize}
          searchableKeys={['name', 'description', 'customSearchable']}
          categoriesColSize={colSize}
          toolbarType={'categoriesDropdown'}
          showLayoutButtons={false}
          configureButtonLabel='Explore'
          headerRowsColors={headerRowsColors}
          filterProducts={filterProducts}
          retrieveProducts={this.retrieveProducts}
          showParentChildCatergories
          openProductsInNewTab
          showBundles
          showFilters
          showHeaderRows
          showAddedToOrderIcon
          showPriceFilter={false}
          displayPriceOnList={false}
          toolbar
          toolbarRenderer={this.renderToolbar}
          orderCategoriesAZ
          productTarget='_blank'
          noAnimation={this.props.noAnimation}
          useCarousel={this.props.useCarousel}
          sortProducts={this.props.sortProducts}
          hidePriceAndDurationIfZero={this.props.hidePriceAndDurationIfZero}
          children={this.props.renderContent}
          renderProductBody={({ props, description, headerRows }) => (<>
            <p>{description}</p>
            {headerRows}
          </>)}
        />
      </StyledList>
    )
  }
}

export default CustomProductList
